import React from 'react'
import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'
import { BrowserRouter} from 'react-router-dom'
import { Helmet } from 'react-helmet'
import { ConfigureStore } from './store/ConfigureStore'
import * as serviceWorker from './serviceWorker'
import App from './App'
import thumbnail from './assets/images/BackgroundMain.jpg'

const store = ConfigureStore()

ReactDOM.render(
  <Provider store={store}>
    <BrowserRouter>
      <App/>
    </BrowserRouter>
  </Provider>,
  document.getElementById('root'),
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()

/* eslint-disable react/state-in-constructor */
import React from 'react';
import PageNotFound from './404';
import '../../assets/css/Error.css';

class ErrorBoundary extends React.Component {
  constructor(props: any) {
    super(props);
    this.state = {
      hasError: false,
      errorInfo_: '',
    };
  }

  static getDerivedStateFromError(error: any) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  componentDidCatch(error: any, errorInfo: any) {
    console.log('error', error, errorInfo);
    this.setState({
      errorInfo_: errorInfo,
    });
    // You can also log the error to an error reporting service
    // logErrorToMyService(error, errorInfo);
  }

  render() {
    // @ts-ignore
    if (this.state.hasError) {
      // You can render any custom fallback UI
      const InternalErrorPage = () => (
        <>
          <PageNotFound
            type="Something went wrong !"
            msg="We are working on it"
          />
        </>
      );
      return InternalErrorPage;
    }

    return this.props.children;
  }
}
export default ErrorBoundary;

import React from 'react';

const PageNotFound = (props: any) => (
  <div id="notfound">
    <div className="notfound">
      <div className="notfound-404">
        <h1>{props.type}</h1>
        <h2>{props.msg}</h2>
      </div>
      <a href="/">Homepage</a>
    </div>
  </div>
);

export default PageNotFound;
